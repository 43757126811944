<template>
  <div id="app">
    <LandPage/>
  </div>
</template>

<script>
import LandPage from './components/LandPage.vue'

export default {
  name: 'App',
  components: {
    LandPage
  }
}
</script>

<style>
html,body{
  margin: 0;
}
#app {
}
</style>
